import {
	Hydrate,
	QueryClient,
	QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ThemeProvider } from 'next-themes'
import type { PropsWithChildren } from 'react'
import { AnalyticsProvider } from 'use-analytics'
import { TooltipProvider } from '~/components/ui/tooltip'
import { analytics } from '~/lib/analytics'

const queryClient = new QueryClient()

export const Providers = ({
	children,
	pageProps,
}: PropsWithChildren<{ pageProps: any }>) => (
	<ThemeProvider
		attribute='class'
		defaultTheme='light'
		enableSystem
		disableTransitionOnChange
	>
		<QueryClientProvider client={queryClient}>
			<Hydrate state={pageProps.dehydratedState}>
				<AnalyticsProvider instance={analytics}>
					<TooltipProvider>{children}</TooltipProvider>
				</AnalyticsProvider>
			</Hydrate>

			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	</ThemeProvider>
)
