import { useAuth } from '@cocoonspace/shared/domains/auth/hooks/use-auth.hook'
import Link from 'next/link'
import { UserMenu } from '~/components/organisms/user-menu'
import { cn } from '~/lib/utils'

export const AppNavbar = () => {
	const { isAuth } = useAuth()

	return (
		<nav
			className={cn(
				'fixed inset-x-0 top-0 z-40 h-[67px] w-full print:hidden',
				'bg-white shadow',
				'flex shrink-0 flex-row items-center px-6',
			)}
		>
			<Link
				href='/'
				className={cn(
					'mr-auto flex flex-row items-start gap-2 pb-1 font-bold text-4xl text-primary-main leading-none',
				)}
			>
				cocoon space
				{process.env.NEXT_PUBLIC_APP_ENV !== 'prod' && (
					<span className='pt-2 text-gray-700 text-xxs uppercase leading-none'>
						{process.env.NEXT_PUBLIC_APP_ENV}
					</span>
				)}
			</Link>

			{isAuth && <UserMenu />}
		</nav>
	)
}
