/**
 * Generated by orval 🍺. Do not edit manually.
 */
import { useQuery } from '@tanstack/react-query'
import type {
	QueryFunction,
	QueryKey,
	UseQueryOptions,
	UseQueryResult,
} from '@tanstack/react-query'
import { useCustomInstance } from '../../axios-client/use-custom-instance'
import type { ErrorType } from '../../axios-client/use-custom-instance'
import type { SidebarResponse } from '../../types/sidebarResponse'
import type { UnauthorizedResponse } from '../../types/unauthorizedResponse'
import type { UnexpectedResponse } from '../../types/unexpectedResponse'

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * Return the sidebar info for the current user.

 * @summary Get sidebar info
 */
export const getSidebar = (
	options?: SecondParameter<typeof useCustomInstance>,
) => {
	return useCustomInstance<SidebarResponse>(
		{ url: `/_admin`, method: 'GET' },
		options,
	)
}

export const getGetSidebarQueryKey = () => {
	return [`/_admin`] as const
}

export const getGetSidebarQueryOptions = <
	TData = Awaited<ReturnType<typeof getSidebar>>,
	TError = ErrorType<UnauthorizedResponse | UnexpectedResponse>,
>(options?: {
	query?: UseQueryOptions<Awaited<ReturnType<typeof getSidebar>>, TError, TData>
	request?: SecondParameter<typeof useCustomInstance>
}) => {
	const { query: queryOptions, request: requestOptions } = options ?? {}

	const queryKey = queryOptions?.queryKey ?? getGetSidebarQueryKey()

	const queryFn: QueryFunction<Awaited<ReturnType<typeof getSidebar>>> = () =>
		getSidebar(requestOptions)

	return {
		queryKey,
		queryFn,
		cacheTime: 6000000,
		staleTime: 3000000,
		...queryOptions,
	} as UseQueryOptions<
		Awaited<ReturnType<typeof getSidebar>>,
		TError,
		TData
	> & { queryKey: QueryKey }
}

export type GetSidebarQueryResult = NonNullable<
	Awaited<ReturnType<typeof getSidebar>>
>
export type GetSidebarQueryError = ErrorType<
	UnauthorizedResponse | UnexpectedResponse
>

/**
 * @summary Get sidebar info
 */
export const useGetSidebar = <
	TData = Awaited<ReturnType<typeof getSidebar>>,
	TError = ErrorType<UnauthorizedResponse | UnexpectedResponse>,
>(options?: {
	query?: UseQueryOptions<Awaited<ReturnType<typeof getSidebar>>, TError, TData>
	request?: SecondParameter<typeof useCustomInstance>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getGetSidebarQueryOptions(options)

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey
	}

	query.queryKey = queryOptions.queryKey

	return query
}
