import { type VariantProps, cva } from 'class-variance-authority'
import type { ComponentProps, PropsWithChildren } from 'react'
import { cn } from '~/lib/utils'

export interface HeaderIconProps
	extends VariantProps<typeof headerIconVariants>,
		ComponentProps<'div'> {}

const headerIconVariants = cva(
	'flex h-[74px] w-[74px] flex-col items-center justify-center rounded-full bg-card text-4xl text-primary-main',
	{
		variants: {
			variant: {
				gray: 'bg-gray-300',
			},
			// padding: {},
		},
		defaultVariants: {},
	},
)

export const HeaderIcon = ({
	className,
	variant,
	children,
}: PropsWithChildren<HeaderIconProps>) => {
	return (
		<div className={cn(headerIconVariants({ variant, className }))}>
			{children}
		</div>
	)
}
