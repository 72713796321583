import { useAuth } from '@cocoonspace/shared/domains/auth/hooks/use-auth.hook'
import { CircleUser } from 'lucide-react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { ActionIcon } from '~/components/atoms/action-icon'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '~/components/ui/dropdown-menu'
import { cn } from '~/lib/utils'

export const UserMenu = ({
	className,
	dense,
}: {
	className?: string
	dense?: boolean
}) => {
	const router = useRouter()
	const { t } = useTranslation()
	const { logout, fireAuthUser } = useAuth()

	return (
		<DropdownMenu>
			<DropdownMenuTrigger
				className={cn('ml-auto flex flex-row items-center gap-4', className)}
			>
				{!dense && (
					<div className='flex-1 truncate text-right'>
						<div className='truncate text-sm'>{fireAuthUser?.displayName}</div>

						<div className='truncate text-gray-800 text-xxs'>
							{fireAuthUser?.email}
						</div>
					</div>
				)}

				{fireAuthUser?.photoURL ? (
					<img
						src={fireAuthUser?.photoURL}
						alt=''
						width={32}
						height={32}
						referrerPolicy='no-referrer'
						className='rounded-full'
					/>
				) : (
					<CircleUser className='text-4xl' />
				)}
			</DropdownMenuTrigger>

			<DropdownMenuContent
				sideOffset={15}
				onCloseAutoFocus={(e) => e.preventDefault()}
			>
				<DropdownMenuItem onClick={() => logout().then(() => router.push('/'))}>
					<ActionIcon actionName='logout' className='mr-2 inline' />

					{t('actions:logout.btnLabel')}
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}
