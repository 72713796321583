import { useRouter } from 'next/router'
import { PrivateLayout } from './private-layout'
import { ScopesLayout } from './scopes-layout'

export const MainLayout = ({ children }: { children: any }) => {
	const router = useRouter()

	const isBypassingAuth = [
		'/login',
		'/quotations/[entryId]/validate/error',
	].includes(router.route)

	const isScopesLayout = [
		'/quotations/[entryId]/validate',
		'/orders/[orderId]/delivery',
	].includes(router.route)

	if (isBypassingAuth) return children
	if (isScopesLayout) return <ScopesLayout>{children}</ScopesLayout>

	return <PrivateLayout>{children}</PrivateLayout>
}
