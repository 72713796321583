import type { SVGProps } from 'react'
import { iconsRef } from '~/config/icons-ref'
import { cn } from '~/lib/utils'

export const DescribeIcon = ({
	describeId,
	className,
	...props
}: {
	describeId: keyof typeof iconsRef.describes
} & Omit<SVGProps<SVGSVGElement>, 'dangerouslySetInnerHTML'> &
	any) => {
	const Component =
		iconsRef.describes[describeId as keyof typeof iconsRef.describes]

	if (!Component) return null

	return <Component {...props} className={cn('shrink-0', className)} />
}
