import { ChevronsLeft, ChevronsRight, Home, Search } from 'lucide-react'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { DescribeIcon } from '~/components/atoms/describe-icon'
import { Button, buttonVariants } from '~/components/ui/button'
import { UserMenu } from '~/components/user-menu'
import { dialogsEmitter } from '~/emitters/dialogs.emitter'
import { useGetSidebar } from '~/lib/admin-sdk-js/domains/sidebar/sidebar'
import type { SidebarItem } from '~/lib/admin-sdk-js/types/sidebarItem'
import { useUserPrefsStore } from '~/stores/user-prefs.store'

export interface MenuItem {
	id?: string
	type: 'section' | 'link' | 'describe'
	label: string
	href?: string
	children?: MenuItem[]
}

export const getMenuItemDef = (items: SidebarItem[]) => {
	return items
		.filter((module) => !module.hidden)
		.reduce<MenuItem[]>((menu, item) => {
			const sectionIndex = menu.findIndex((menuI) => menuI.id === item.section)

			const link: MenuItem = {
				id: item.id,
				type: 'link',
				label: `modules:${item.id}_other`,
				href: `/${item.id}`,
			}

			if (sectionIndex !== -1) {
				menu[sectionIndex].children?.push(link)
			} else {
				menu.push({
					id: item.section,
					type: 'section',
					label: `modules:${item.section}_one`,
					children: [link],
				})
			}

			return menu
		}, [])
}

const shortcuts = {
	events: '⌘E',
	users: '⌘U',
	planning: '⌘P',
} as const

const ShortcutLogo = ({ id }: { id: keyof typeof shortcuts }) => (
	<>
		{Object.keys(shortcuts).includes(id) && (
			<span className='ml-auto text-slate-400'>{shortcuts[id]}</span>
		)}
	</>
)

export const Sidebar = () => {
	const router = useRouter()
	const { t } = useTranslation()

	const { data: sidebarMenu } = useGetSidebar({
		query: {
			select: (res) => (res.data ? getMenuItemDef(res.data) : []),
		},
	})

	const isSidebarVisible = useUserPrefsStore.use.isSidebarVisible()
	const toggleSidebar = useUserPrefsStore.use.toggleSidebar()

	useEffect(() => {
		const down = (e: KeyboardEvent) => {
			if (e.key === 'e' && (e.metaKey || e.ctrlKey)) {
				e.preventDefault()
				router.push('/events')
			} else if (e.key === 'p' && (e.metaKey || e.ctrlKey)) {
				e.preventDefault()
				router.push('/planning')
			} else if (e.key === 'u' && (e.metaKey || e.ctrlKey)) {
				e.preventDefault()
				router.push('/users')
			}
		}

		document.addEventListener('keydown', down)

		return () => document.removeEventListener('keydown', down)
	}, [])

	return (
		<aside
			data-testid='sidebar'
			className='flex w-full flex-col divide-y overflow-hidden border-r bg-slate-50 md:w-[180px] dark:bg-card'
		>
			<div className='flex w-full items-center justify-between gap-1 px-4 py-2'>
				<div className='flex flex-row items-center gap-2'>
					<Link
						href='/'
						className='group flex size-[30px] items-center justify-center rounded-full hover:bg-primary'
					>
						<Image
							src='/images/logo.png'
							alt='cocoon'
							width={30}
							height={30}
							className='group-hover:hidden'
						/>

						<Home className='hidden size-4 text-primary-foreground group-hover:block' />
					</Link>

					{process.env.NEXT_PUBLIC_APP_ENV === 'preprod' && (
						<div className='font-semibold text-[10px] text-primary text-slate-500 uppercase'>
							preprod
						</div>
					)}
				</div>

				<Button
					data-testid='toggle-sidebar-btn'
					size='icon'
					variant='ghost'
					className='-mr-3 shrink-0'
					onClick={() => toggleSidebar()}
				>
					{isSidebarVisible ? (
						<ChevronsLeft className='size-4' />
					) : (
						<ChevronsRight className='size-4' />
					)}
				</Button>
			</div>

			<div className='p-4'>
				<Button
					className='w-full text-xs max-md:hidden'
					onClick={() => dialogsEmitter.emit('availabilities.open')}
				>
					<Search className='mr-2 size-4 text-sm' />

					{t('quotations:availabilities.checkAvailBtn')}
				</Button>
			</div>

			<div className='flex flex-1 flex-col gap-2 overflow-y-auto p-4'>
				{sidebarMenu?.map((group) => (
					<div key={group.id} data-testid='sidebar-group'>
						<div
							data-testid='sidebar-group-label'
							className='mb-1 flex w-full flex-row items-center truncate text-[10px] text-gray-700 uppercase'
						>
							{t(group.label)}
						</div>

						<div>
							{group.children?.map((item) => (
								<Link
									key={item.label}
									data-testid='sidebar-group-link'
									href={`/${item.id}`}
									className={buttonVariants({
										size: 'sm',
										variant:
											item.id === router.query.describeId ? 'outline' : 'ghost',
										className: '!justify-start flex w-full',
									})}
								>
									<DescribeIcon
										describeId={item.id as any}
										className='mr-2'
										size={14}
									/>

									{t(item.label)}

									<ShortcutLogo id={item.id as any} />
								</Link>
							))}
						</div>
					</div>
				))}
			</div>

			<div className='p-2'>
				<UserMenu />
			</div>
		</aside>
	)
}
