import { AXIOS_INSTANCE } from '@cocoonspace/sdk-js/axios-client/use-custom-instance'
import { getFirebaseApp } from '@cocoonspace/shared/libs/firebase'
import { dateTimeUtils } from '@cocoonspace/shared/utils/date-time-utils'
import { installTwicPics } from '@twicpics/components/react'
import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { Nunito } from 'next/font/google'
import Head from 'next/head'
import { MainLayout } from '~/components/layouts/main-layout'
import { Providers } from '~/components/providers'
import { Toaster } from '~/components/ui/sonner'
import { accessTokenInterceptor } from '~/lib/interceptors/access-token.interceptor'
import { onErrorInterceptor } from '~/lib/interceptors/on-error.interceptor'
import 'intl-pluralrules'
import '@twicpics/components/style.css'
import '~/styles/globals.css'
import '~/styles/themes.css'
import { AXIOS_INSTANCE as ADMIN_AXIOS_INSTANCE } from '~/lib/admin-sdk-js/axios-client/use-custom-instance'
import i18nextConfig from '../../next-i18next.config'

const CheckTimeSlotsDialog = dynamic(
	() =>
		import('~/domains/quotations/components/templates/check-time-slots-dialog'),
)

getFirebaseApp({
	appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
	apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
	authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
	storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
	measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
	messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
})

dateTimeUtils.locale('fr')

installTwicPics({
	domain: process.env.NEXT_PUBLIC_TWICPICS_DOMAIN!,
	maxDPR: parseInt(process.env.NEXT_PUBLIC_TWICPICS_MAX_DPR!),
	path: process.env.NEXT_PUBLIC_TWICPICS_PATH,
})

export const customFont = Nunito({
	subsets: ['latin'],
	display: 'swap',
	variable: '--font-sans',
})

ADMIN_AXIOS_INSTANCE.interceptors.request.use(accessTokenInterceptor)
ADMIN_AXIOS_INSTANCE.interceptors.response.use((res) => res, onErrorInterceptor)

AXIOS_INSTANCE.interceptors.request.use(accessTokenInterceptor)
AXIOS_INSTANCE.interceptors.response.use((res) => res, onErrorInterceptor)

const App = ({ Component, pageProps }: AppProps) => (
	<Providers pageProps={pageProps}>
		<Head>
			<title>{process.env['NEXT_PUBLIC_NAME']}</title>

			<style>
				{`:root { --font-sans: ${customFont.style.fontFamily}; }`}

				{`.MuiDataGrid-root{
						font-family: ${customFont.style.fontFamily} !important;
					}`}
			</style>

			<meta name='robots' content='noindex'></meta>
		</Head>

		<MainLayout>
			<Component {...pageProps} />
		</MainLayout>

		<CheckTimeSlotsDialog />

		<Toaster richColors />
	</Providers>
)

export default appWithTranslation(App, i18nextConfig as any)
