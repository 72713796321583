import { authEmitter } from '@cocoonspace/shared/domains/auth/emitters/auth.emitter'
import { useAuth } from '@cocoonspace/shared/domains/auth/hooks/use-auth.hook'
import { useAuthDialogStore } from '@cocoonspace/shared/domains/auth/stores/use-auth-dialog-store.hook'
import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { Button } from '~/components/ui/button'
import { Form } from '~/components/ui/form'
import { FormControlledInput } from '~/components/ui/form-controlled-input'
import { Input } from '~/components/ui/input'
import { SocialAuthList } from './social-auth-list'

type FormData = z.infer<typeof loginSchema>

const loginSchema = z.object({
	email: z
		.string()
		.min(1, { message: 'errors:forms.required' })
		.email({ message: 'errors:forms.invalidEmail' }),
	password: z.string().min(1, { message: 'errors:forms.required' }),
})

export const LoginForm = () => {
	const router = useRouter()
	const { t } = useTranslation()
	const { login, authError } = useAuth()

	const closeDialog = useAuthDialogStore((state) => state.closeDialog)
	const setActiveTab = useAuthDialogStore((state) => state.setActiveTab)

	const form = useForm<FormData>({
		resolver: zodResolver(loginSchema),
		values: {
			email: '',
			password: '',
		},
	})

	const onSubmitForm = (data: FormData) =>
		login('password', {
			email: data.email!,
			password: data.password!,
		}).then((res) => {
			if (res?.user) {
				if (typeof router.query.continueUrl === 'string') {
					router.push(router.query.continueUrl)
				}

				authEmitter.emit('login.success')
				closeDialog()
			}
		})

	return (
		<div className='flex min-h-[200px] flex-col gap-6'>
			<form noValidate onSubmit={form.handleSubmit(onSubmitForm)}>
				<Form {...form}>
					{!!authError && (
						<p className='mb-4 text-primary text-sm'>{t(authError)}</p>
					)}

					<div className='flex flex-col gap-6'>
						<div className='flex flex-col'>
							<FormControlledInput
								name='email'
								label={t('attributesLabels:email')}
								control={form.control}
								render={({ field, fieldState }) => (
									<Input {...field} type='email' required />
								)}
								required
							/>

							<FormControlledInput
								name='password'
								label={t('attributesLabels:password')}
								control={form.control}
								render={({ field, fieldState }) => (
									<Input {...field} type='password' required />
								)}
								required
							/>

							<div className='text-right'>
								<Button
									variant='link'
									size='sm'
									type='button'
									onClick={() => setActiveTab('forgottenPass')}
								>
									{t('auth:login.recover')}
								</Button>
							</div>
						</div>

						<Button
							type='submit'
							data-testid='login-btn'
							className='w-full'
							disabled={form.formState.isSubmitting}
						>
							{t('actions:login.btnLabel')}
						</Button>
					</div>
				</Form>
			</form>

			<p className='text-center'>{t('auth:login.other')}</p>

			<SocialAuthList />

			<div className='flex flex-row justify-center gap-2'>
				{t('auth:login.noAccount')}

				<button
					data-testid='goto-register-view-button'
					type='button'
					className='text-primary'
					onClick={() => setActiveTab('register')}
				>
					{t('actions:register.btnLabel')}
				</button>
			</div>
		</div>
	)
}
