import { authEmitter } from '@cocoonspace/shared/domains/auth/emitters/auth.emitter'
import { useAuth } from '@cocoonspace/shared/domains/auth/hooks/use-auth.hook'
import { useAuthDialogStore } from '@cocoonspace/shared/domains/auth/stores/use-auth-dialog-store.hook'
import { useValidateEmail } from '@cocoonspace/shared/hooks/use-emailable'
import { zodResolver } from '@hookform/resolvers/zod'
import { Trans, useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { Button, buttonVariants } from '~/components/ui/button'
import { Form } from '~/components/ui/form'
import { FormControlledInput } from '~/components/ui/form-controlled-input'
import { Input } from '~/components/ui/input'
import { ValidatedEmailInput } from '~/components/ui/validated-email-input'
import { SocialAuthList } from './social-auth-list'

type FormData = z.infer<typeof registerSchema>

const registerSchema = z.object({
	email: z.string().email({ message: 'errors:forms.invalidEmail' }),
	password: z
		.string()
		.min(8, { message: 'errors:forms.password.minLength' })
		.regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/, {
			message: 'errors:forms.password.specialCharacters',
		}),
})

export const RegisterForm = () => {
	const { t } = useTranslation()
	const { createUserWithEmailAndPassword, authError } = useAuth()
	const closeDialog = useAuthDialogStore((state) => state.closeDialog)
	const setActiveTab = useAuthDialogStore((state) => state.setActiveTab)
	const initialData = useAuthDialogStore((state) => state.initialData)

	const form = useForm<FormData>({
		resolver: zodResolver(registerSchema),
		values: {
			email: '',
			password: '',
			...initialData,
		},
	})

	const { validate } = useValidateEmail(form)

	const onSubmitForm = async (data: FormData) => {
		const isValid = await validate(data.email!)

		if (isValid) {
			return createUserWithEmailAndPassword(data.email!, data.password!).then(
				(res) => {
					if (res?.user) {
						authEmitter.emit('register.success')
						closeDialog()
					}
				},
			)
		}
	}

	return (
		<div className='flex flex-col gap-6'>
			{!!authError && (
				<p className='mb-4 text-primary text-sm'>{t(authError)}</p>
			)}

			<form
				className='flex flex-col'
				noValidate
				onSubmit={form.handleSubmit(onSubmitForm)}
			>
				<Form {...form}>
					<div className='flex flex-col'>
						<ValidatedEmailInput
							name='email'
							label={t('attributesLabels:email')}
							required
						/>

						<FormControlledInput
							name='password'
							label={t('attributesLabels:password')}
							control={form.control}
							render={({ field, fieldState }) => (
								<Input {...field} type='password' required />
							)}
							required
						/>
					</div>

					<Trans
						parent='p'
						className='mb-4 text-center text-sm'
						i18nKey='auth:register.legal'
						components={{
							cguLink: (
								<Link
									className={buttonVariants({ variant: 'link', size: 'sm' })}
									href='/mentions-legales'
									target='_blank'
								/>
							),
						}}
					/>

					<Button
						data-testid='register-btn'
						className='w-full'
						type='submit'
						disabled={form.formState.isSubmitting}
					>
						{t('actions:createMyAccount.btnLabel')}
					</Button>
				</Form>
			</form>

			<p className='text-center'>{t('auth:register.other')}</p>

			<SocialAuthList />

			<div className='flex flex-row justify-center gap-2'>
				{t('auth:register.account')}

				<button
					type='button'
					className='text-primary'
					onClick={() => setActiveTab('login')}
				>
					{t('actions:login.btnLabel')}
				</button>
			</div>
		</div>
	)
}
