import * as Sentry from '@sentry/nextjs'
import { i18n } from 'next-i18next'
import { toast } from 'sonner'

export const onErrorInterceptor = (err: any) => {
	console.info('API ERROR: ', err) // eslint-disable-line

	if (err.response?.status !== 401) {
		Sentry.captureException(err, {
			contexts: {
				api: {
					requestId: err.response?.headers?.['request-id'],
					message: err.message,
				},
			},
		})
	}

	toast.error(
		i18n?.t([
			`errors:api.${err.response?.data?.errors[0].code}`,
			err.response?.data?.errors[0].title,
		]),
	)

	return Promise.reject(err)
}
