import type { SVGProps } from 'react'
import { iconsRef } from '~/config/icons-ref'
import { cn } from '~/lib/utils'

export const ActionIcon = ({
	actionName,
	className,
	...props
}: {
	actionName: keyof typeof iconsRef.actions
	className?: string
} & Omit<SVGProps<SVGSVGElement>, 'dangerouslySetInnerHTML'> &
	any) => {
	const Component =
		iconsRef.actions[actionName as keyof typeof iconsRef.actions]

	if (!Component) return null

	return <Component {...props} className={cn('size-4', className)} />
}
